/* Reset some default styles */
body, h1, h2, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f8f8; /* Light background color */
  color: #333; /* Dark text color */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Navbar Styles */
nav {
  background-color: #ffcc29; /* Sunflower yellow background for the navbar */
  padding: 10px 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: #fff; /* Change color on hover */
}

/* Page Styles */
.home,
.about,
.contact,
.faq {
  background-color: #fff; /* White background for content sections */
  padding: 40px 20px;
  margin: 20px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Subtle box shadow for a lift */
  border-radius: 8px; /* Rounded corners for a softer look */
}

.stretch-image {
  width: 100%; /* Make the image stretch across its container */
  max-height: 300px; /* Set a maximum height to avoid taking up too much space */
  object-fit: cover; /* Ensure the image covers the container */
  border-radius: 8px; /* Rounded corners for a softer look */
}

.center-text {
  text-align: center;
}

.about-content {
  max-width: 600px;
  margin: 20px auto;
}

h1, h2 {
  color: #333; /* Dark text color */
}

p {
  line-height: 1.6;
  color: #555; /* Slightly darker text color */
}

/* Add this style to your existing CSS file */
.office-address {
  background-color: #fff; /* White background for the address section */
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift */
  border-radius: 8px; /* Rounded corners for a softer look */
}

.office-address h3 {
  color: #333; /* Dark text color for heading */
}

.office-address p {
  margin-top: 10px;
  color: #555; /* Slightly darker text color for the address */
}


/* Contact Form Styles */
.contact form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.contact label {
  margin-bottom: 10px;
}

.contact input, .contact textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 4px;
}

.contact button {
  background-color: #ffcc29; /* Sunflower yellow button */
  color: #fff; /* White text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.contact button:hover {
  background-color: #ffdb58; /* Lighter yellow on hover */
}

.visit-us {
  background-color: #fff; /* White background for the visit us section */
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift */
  border-radius: 8px; /* Rounded corners for a softer look */
}

.visit-us h3 {
  color: #333; /* Dark text color for heading */
}

.visit-us p {
  margin-top: 10px;
  color: #555; /* Slightly darker text color for the address */
}

/* FAQ Styles */
.faq p {
  margin-bottom: 20px;
}

.faq strong {
  color: #333; /* Dark text color for strong elements */
}

/* Footer Styles */
footer {
  background-color: #333; /* Dark background color for the footer */
  color: #fff; /* White text color */
  padding: 20px 0;
  text-align: center;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav a {
    margin-bottom: 10px;
  }

  .contact form {
    max-width: 100%;
  }
}
